<template>
  <!-- <van-pull-refresh :head-height="50" v-model="refreshing" @refresh="onRefresh"> -->
  <div id="guider">
    <van-sticky :offset-top="0">
    <div class="header">
      <van-row gutter="10">
        <van-col span="10" class="header-title" @click="onClickLeft">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
          />导游详情
        </van-col>
        <van-col
          span="10"
          class="header-title"
          style="text-align: right"
          v-if="guide.status == '2'"
          @click="onStatusClick(1)"
        >
          审核通过
        </van-col>
         <van-col
          span="4"
          class="header-title"
          style="text-align: right;color:#de350b;"
          v-if="guide.status == '2'"
          @click="onStatusClick(3)"
        >
          拒绝
        </van-col>
        <van-col
          span="14"
          class="header-title"
          style="text-align: right"
          v-if="guide.status == '1'||guide.status == '4'"
          @click="onDeleteClick"
        >
          删除
        </van-col>
      </van-row>
    </div>
    </van-sticky>
    <van-dialog v-model="showReject" title="审核拒绝" show-cancel-button :beforeClose="beforeClose">
        <van-field
      v-model="reject_reason"
      label="拒绝原因"
      placeholder="请输入拒绝原因"
   />
    </van-dialog>
    <div class="guider-wrapper">
      <div class="guider-container">
        <div class="guider-avatar">
          <van-image
            style="width: 1.5rem; height: 1.5rem"
            round
            :src="guide.avatar ? guide.avatar : ''"
            ><template v-slot:error
              ><span style="font-size: 10px">暂无头像</span></template
            ></van-image
          >
        </div>

        <van-row>
          <van-col span="24"
            ><div class="star-text">
              <!-- <van-icon
                color="#FFAA00"
                v-for="(item, index) in 5"
                :key="index"
                name="star"
              /> -->
              <van-rate
                v-model="guide.rate_star"
                :size="25"
                color="#FFAA00"
                void-icon="star"
                void-color="#eee"
                readonly
                allow-half
              /></div
          ></van-col>
          <van-col span="24"
            ><div class="main-text">
              {{ guide.name ? guide.name : "未完善" }}
               <span v-if="guide.attach_status=='1'||guide.attach_status=='2'">
                  <svg-icon
                  :data_iconName="'temp'"
                  :className="''"
                /></span>
            </div></van-col
          >
          <van-col span="24"
            ><div class="guider-text">
              性别：{{ guide.gender ? guide.gender : "未完善" }}
            </div></van-col
          >
          <van-col span="24"
            ><div class="guider-text">
              地区：{{(guide.province?guide.province:'')+'/'+(guide.city?guide.city:'')+'/'+(guide.district?guide.district:'')}}
            </div>
          </van-col>
          <van-col span="24"
            ><div class="guider-text">
              角色：{{guide.role_name}}
            </div>
          </van-col>
          <van-col span="24"
            ><div class="guider-text">
              {{guide.role_name}}证：<span
                v-if="guide.tourist_card"
                style="color: #436def; text-decoration: underline"
                @click="tourist_card_image_show = true"
                >{{ guide.tourist_card }}</span
              >
              <span v-if="!guide.tourist_card">未完善</span>
            </div>
            <van-popup v-model="tourist_card_image_show">
              <van-image
                v-if="guide.tourist_card_image"
                :src="guide.tourist_card_image"
              >
                <template v-slot:error>未完善</template>
              </van-image>
              <van-empty
                v-if="!guide.tourist_card_image"
                image="error"
                description="未完善"
              />
            </van-popup>
          </van-col>
          <van-col span="24"
            ><van-row class="guider-text" type="flex">
              <div style="width:20%"></div>
              <div>
                {{guide.role_name}}资格证：
              </div>
              <div
                v-if="guide.tourist_certificate"
                style="color: #436def; text-decoration: underline"
                @click="onImgClick(guide.tourist_certificate_image)"
                >
                <div style="text-align:left">
                  <splitString :value="guide.tourist_certificate"></splitString>
                </div>
              </div>
              <span v-if="!guide.tourist_certificate">未完善</span>
            </van-row>
            <van-popup v-model="tourist_certificate_image_show">
              <van-empty
                image="error"
                description="未完善"
              />
            </van-popup>
          </van-col>
          <van-col span="24" v-if="guide.role_name == '导游'"
            ><div class="guider-text">
              导游级别：{{ guide.level ? guide.level : "未完善" }}
            </div></van-col
          >
          <van-col span="24"
            ><div class="guider-text">
              {{guide.role_name}}语种：{{ guide.language ? guide.language : "未完善" }}
            </div></van-col
          >
          <van-col span="24"
            ><div class="guider-text" @click="onAgencyClick">
              所属旅行社：
              <span
                style="color: #436def; text-decoration: underline"
                v-if="guide.travel_company"
                >{{ guide.travel_company }}</span>
                <svg-icon
                   v-if="guide.is_diff_region"
                  :data_iconName="'diff'"
                  :className="'diff-icon'"
                />
              <span v-if="!guide.travel_company">暂无</span>
            </div></van-col
          >
          <van-col span="24"
            ><div class="guider-text">
              联系电话：<a
                v-if="guide.mobile"
                :href="'tel:' + guide.mobile"
                style="color: #436def; text-decoration: underline"
                >{{ guide.mobile }}</a
              >
              <span v-if="!guide.mobile">暂无联系电话</span>
            </div>
          </van-col>
        </van-row>
        <div class="qr-container">
          <van-row>
            <van-col span="24"
              ><div class="guider-text">
                <div class="qr-img">
                  <vue-qr
                    :text="downloadData.url"
                    :margin="0"
                    colorDark="#333"
                    colorLight="#fff"
                    :logoScale="0.2"
                    :size="200"
                  ></vue-qr>
                </div>
              </div>
            </van-col>
            <!-- <van-col span="24"><div class="guider-text">营业截止日期</div></van-col> -->
            <van-col class="text-center" span="24"
              ><div class="state">{{ guide.status_text }}</div></van-col
            >
          </van-row>
        </div>
        <van-row>
          <van-col span="24"> 
            <van-cell
              title="查看评价"
              is-link
              :to="'/guider/feedbacks?id=' + $route.query.id"
            />
          </van-col>
          <van-col span="24" v-if="guide.attach_status=='1'||guide.attach_status=='2'">
            <van-cell
              title="查看借调旅行社"
              is-link
              :to="'/guider/temp_agency?id=' + $route.query.id"
            />
          </van-col>
        </van-row>
      </div>
    </div>
  </div>
  <!-- </van-pull-refresh> -->
</template>

<script>
import { Toast } from "vant";
import { Dialog } from "vant";
import vueQr from "vue-qr";
import { QrURL } from '@/services/config'
import splitString from "@/views/components/SplitString"
import {ImagePreview} from "vant"
export default {
  name: "guider-item",
  components: { vueQr, splitString },
  data() {
    const _self = this;
    return {
      refreshing: false,
      loading: true,
      showReject:false,
      reject_reason:"",
      tourist_card_image_show: false,
      tourist_certificate_image_show: false,
      guide: {},
      downloadData: {
        url: `${QrURL}/guider/info/${_self.$route.query.id}`,//测试
        //  url: `https://visitor.wenlvanquan.com/guider/info/${_self.$route.query.id}`,//正式
        icon: ""
      },
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    async get_guide(id) {
      const res = await this.$apis.get_guide(id);
      this.guide = res.data;
      this.loading = false;
      this.refreshing = false;
    },
    async examine(status) {
      const _self = this;
      const id = _self.$route.query.id;
      const data = {
        guide: {
          status: status,
          reject_reason:_self.reject_reason
        },
      };
      const res = await _self.$apis.put_guides_status(data, id);
      if (res.status == 200) {
        Toast("操作成功");
        _self.onRefresh();
         this.showReject = false
        this.reject_reason = ""
      }
    },
    beforeClose(action, done) {
      if(action=='confirm'){
        if(!this.reject_reason ) {
          this.$toast("请输入拒绝原因")
          done(false) 
          return false
        }
        this.examine(3)
      }
      if(action=='cancel'){
        this.showReject = false
      }
    },
    onStatusClick(status) {
      const _self = this;
      if(status==3){
        this.showReject = true
        return false
      }
      Dialog.confirm({
        title: "温馨提示",
        message: status==1?"确认通过审核吗":"确认拒绝吗",
      })
        .then(() => {
          _self.examine(status);
        })
        .catch(() => {});
    },
    onDeleteClick() {
      const _self = this;
      Dialog.confirm({
        // title: "标题",
        message: "确定删除该导游吗?",
      })
        .then(() => {
          // on confirm
          _self.delete_guides();
        })
        .catch(() => {
          // on cancel
        });
    },
    onAgencyClick() {
       if(this.guide.is_diff_region){
         Toast('异地旅行社，无法查看')
         return false
        }
      this.$router.push({
       
        path: "/agency/item",
        query: {
          id: this.guide.travel_company_id,
        },
      });
    },
    async delete_guides() {
      const id = this.$route.query.id;
      const res = await this.$apis.delete_guides(id);
      if (res.status == 200) {
        Toast("删除成功");
        this.$router.go(-1);
      }
    },
    onRefresh() {
      this.finished = false;
      this.loading = true;
      const id = this.$route.query.id;
      if (!id) {
        Toast("参数错误");
        this.$router.go(-1);

        return false;
      }
      this.get_guide(id);
    },
    onImgClick(url) {
      if(url == '') {
        this.tourist_certificate_image_show = true
      } else {
        ImagePreview(url.split(','))
      }
    }
  },
  created() {
    this.onRefresh();
  },
};
</script>

<style scoped>
#guider {
  height: 100vh;
  /* overflow-y: scroll; */
  /* padding-top:10vh; */
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.guider-wrapper {
  padding: 0.4rem;
  position: relative;
}
.guider-container {
  padding: 0.4rem;
  background-color: #fff;
  border-radius: 20px;
  border-radius: 20px;
  /* position: relative; */
  height: 85vh;
  overflow-y: scroll;
  padding-bottom: 6rem;
}
.guider-avatar {
  position: absolute;
  top: -0.35rem;
  left: 50%;
  transform: translateX(-50%);
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  z-index: 100;
}

.guider-text {
  font-size: 14px;
  color: #666666;
  margin-top: 0.2rem;
  text-align: center;
}
.main-text {
  font-size: 16px;
  color: #333333;
  text-align: center;
  font-weight: bold;
}
.star-text {
  text-align: center;
  font-size: 18px;
  margin-top: 30px;
}
.agency-a {
  color: #1989fa;
}
.sub-text {
  font-size: 14px;
  color: #666666;
  margin-top: 5px;
  text-align: center;
}
.qr-container {
  margin-top: 20px;
}
.qr-img {
  background: #ebebeb;
  border-radius: 28px;
  border-radius: 28px;
  width: 200px;
  height: 200px;
  line-height: 160px;
  margin: 0 auto;
  margin-bottom: 10px;
  padding: 20px;
}
.state {
  background: rgba(64, 169, 255, 0.16);
  border-radius: 17px;
  border-radius: 17px;
  height: 34px;
  min-width: 80px;
  line-height: 34px;
  padding: 0 10px;
  margin: 0 auto;
  margin-top: 10px;
  color: #40a9ff;
  font-size: 14px;
  text-align: center;
  display: inline-block;
}
.text-center {
  text-align: center;
}
</style>
